import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import { Map } from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import "./styles/global.css";
import smoothscroll from 'smoothscroll-polyfill';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
import { createPinia, PiniaVuePlugin } from 'pinia';

Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false
smoothscroll.polyfill();

new Vue({
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
