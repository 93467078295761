<template>
  <v-app>
    <v-main>
      <v-progress-linear
        v-if="pageLoading.value"
        indeterminate
        style="height: 10px"
        color="red"
      ></v-progress-linear>
      <a
        v-if="config.frontend.showLandingPageLink"
        style="
          text-decoration: none;
          margin-right: 10px;
          color: white;
          font-weight: bold;
          position: absolute;
          top: 5px;
          z-index: 11;
          right: 5px;
          border-radius: 5px;
          padding-top: 2px;
          padding-left: 5px;
          padding-right: 5px;
        "
        :style="topBarColor"
        href="about.html"
        >ÜBER CLEVERROUTE</a
      >
      <!-- </div> -->
      <Hero
        :destinationName="config.frontend.placename"
        :headerTitleParts="config.frontend.headerTitleParts"
        :height="heroHeight"
        :blur="config.frontend.blurHero"
        :showHeaderCRlogo="config.frontend.showHeaderCRlogo"
      />
      <Searchbar
        ref="searchbar"
        :destinations="config.destinations"
        :params="searchParams"
        :loading="searchRunning.value || pageLoading.value"
        :queryHandler="fetchSingleTransportGroupRoutesWrapper"
        queryOnInput
        :updateIncludeIntermodal="updateIncludeIntermodal"
        :updateMinutesCongestion="updateMinutesCongestion"
        :updateMinutesParking="updateMinutesParking"
        :locInputChanged="locInputChanged"
        :searchBarWithBorder="true"
        :config="config"
      />
      <div :style="`min-height: ${heroHeight}px`">
        <PollOverview
          v-if="usePoll"
          :routes="routes"
          :config="config"
        ></PollOverview>
        <TransportListing
          ref="routelist"
          v-if="routes.length > 0"
          :config="config"
          :routes="routes"
          :searchParams="searchParams"
          :rideshareHost="rideshareHost"
          :reservationToolHost="reservationToolHost"
          :params-updated-at="config.transportsUpdatedAt"
          :onlyJourneyThere="onlyJourneyThere"
          :showMoreRoutesHint="showMoreRoutesHint"
          :showMoreRoutesClicked="showMoreRoutesClicked"
          :loading="searchRunning.value"
          :percentageTransportsComputed="percentageTransportsComputed"
        />
        <!-- if it's a desktop screen then order map and toolbar next to each other -->
        <div
          v-if="!isSmallScreenWidth()"
          style="
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            background-color: white;
          "
          :style="`min-height: ${mapHeightDesktopScreen}`"
        >
          <MapOverviewToolbar
            style="
              width: 50%;
              margin-right: 5px;
              margin-top: 10px;
              margin-bottom: 10px;
              margin-left: 10px;
            "
            :updateCheckedTransports="updateCheckedTransports"
            :checkedTransportsDict="checkedTransportsDict"
            :updateCheckedProviders="updateCheckedProviders"
            :checkedProvidersDict="checkedProvidersDict"
            :routes="routes"
            :routesByTransportType="routesByTransportType"
            :transports="config.transports"
          />

          <Map
            style="
              flex-grow: 1;
              min-height: 500px;
              margin-top: 10px;
              margin-bottom: 10px;
              margin-right: 10px;
              z-index: 0;
            "
            :height="mapHeightDesktopScreen"
            :geojsonRoutes="computeToShowRoutes"
            :checkedTransportsDict="checkedTransportsDict"
            :checkedProvidersDict="checkedProvidersDict"
            :originLonLatStr="searchParams.originLonLat"
            :destinationLonLatStr="searchParams.destinationLonLat"
            :gesture-handling="$vuetify.breakpoint.mdAndDown"
            :routes="routes"
            :transports="config.transports"
            :arrival="String(searchParams.arrival)"
            :departure="String(searchParams.departure)"
            :mapRouteColor="config.frontend.mapRouteColor"
            :mapIconColor="config.frontend.mapIconColor"
            :thirdparty="config.thirdparty"
          />
        </div>
        <!-- but if it's a mobile screen then order map and toolbar vertically and set height to 300 px -->
        <div
          v-else
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            background-color: white;
          "
          :style="`min-height: ${mapHeightMobileScreen}`"
        >
          <Map
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              margin-right: 20px;
              margin-left: 20px;
              z-index: 0;
              height: 100%;
            "
            :height="mapHeightMobileScreen"
            :geojsonRoutes="computeToShowRoutes"
            :checkedTransportsDict="checkedTransportsDict"
            :checkedProvidersDict="checkedProvidersDict"
            :originLonLatStr="searchParams.originLonLat"
            :destinationLonLatStr="searchParams.destinationLonLat"
            :gesture-handling="$vuetify.breakpoint.mdAndDown"
            :routes="routes"
            :transports="config.transports"
            :arrival="String(searchParams.arrival)"
            :departure="String(searchParams.departure)"
            :mapRouteColor="config.frontend.mapRouteColor"
            :mapIconColor="config.frontend.mapIconColor"
            :thirdparty="config.thirdparty"
          />

          <MapOverviewToolbar
            style="
              height: 100%;
              margin-right: 20px;
              margin-top: 10px;
              margin-bottom: 10px;
              margin-left: 20px;
            "
            :updateCheckedTransports="updateCheckedTransports"
            :checkedTransportsDict="checkedTransportsDict"
            :updateCheckedProviders="updateCheckedProviders"
            :checkedProvidersDict="checkedProvidersDict"
            :routes="routes"
            :routesByTransportType="routesByTransportType"
            :transports="config.transports"
          />
        </div>
        <v-container>
          <v-row justify="center" class="my-8">
            <div
              class="text-h6 font-weight-light d-flex flex-column align-center"
            >
              Alle Angaben ohne Gewähr. Stand der Parameter:
              {{ new Date(config.transportsUpdatedAt).toLocaleDateString() }}
            </div>
          </v-row>
        </v-container>
      </div>

      <v-snackbar v-model="error.show" bottom right multi-line color="error">
        <v-icon>warning</v-icon>
        {{ error.msg }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="error.show = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <Footer :frontCfg="config.frontend" />
  </v-app>
</template>

<script>
import {
  clone,
  fromQueryString,
  toQueryString,
} from "@norwin/javascript-is-bad";
import { from, mergeMap, skipUntil } from "rxjs";
import Vue from "vue";
import { fetchRouteParamsValid, fetchSingleRoute } from "./api";
import Footer from "./components/Footer.vue";
import Hero from "./components/Hero.vue";
import LandingInfo from "./components/LandingInfo.vue";
import Map from "./components/Map.vue";
import MapOverviewToolbar from "./components/MapOverviewToolbar.vue";
import PollOverview from "./components/poll/PollOverview.vue";
import Searchbar from "./components/Searchbar.vue";
import TransportListing from "./components/TransportListing.vue";
import { config, frontendConfig } from "./config";
import { TransportTypesArray } from "./models/constants-transports";
import { TransportModalTypes } from "./models/transport";
import * as utils from "./utils";
import { usePollStore } from "./store/poll-store";
import { useConfigStore } from "./store/config-store";
import { storeToRefs } from "pinia";

export default Vue.extend({
  name: "App",

  components: {
    Searchbar,
    Hero,
    Map,
    LandingInfo,
    TransportListing,
    Footer,
    MapOverviewToolbar,
    PollOverview,
  },

  data() {
    return {
      pageLoading: true,
      searchRunning: false,
      showMoreRoutesHintClicked: false,
      nLoadedRoutes: 0,
      heroHeight: 400,
      routes: [],
      onlyJourneyThere: false,
      searchParams: {
        origin: "",
        arrival: "",
        originLonLat: "",
        destinationLonLat: "",
        destinationId: "",
        departure: "",
        destination: undefined,
        dayTicket: false,
        includeIntermodal: false,
        minutesCongestion: 0,
        minutesParking: 0,
        useDepartureTimeForJourneyThere: false,
      },
      error: { show: false, msg: "" },
      config: {
        frontend: {
          showHeaderCRlogo: false,
          showLandingPageLink: false,
          topBarColor: undefined,
          includeIntermodal: false,
        },
        destinations: [{ id: "default" }],
        transports: [],
      },
      percentageTransportsComputed: 0,
      transportNameToFilter: undefined, //"carsharing_scouter", //"ride_and_carshare_scouter", // "park_and_ride_and_bike", //"train", //"bike_ride_and_carshare_stadtmobil", //"ride_and_carshare_cambio_regional", //"train", //"carsharing_alltodrive", //"park_and_ride_pendlerportal", // "train",//"park_and_ride_and_bike", //"train", //"ride_and_carshare_cambio_regional", //"ride_and_carshare_cambio", //"ride_and_taxi", //"ride_and_carshare_cambio", //"park_and_ride_pendlerportal", //"ride_and_carshare_cambio", //"ride_and_carshare_regiomobil_verbrenner",//"park_and_ride",//"car", //"ride_and_carshare_yourcar", //"ride_and_carshare_regiomobil_elektro", //"park_and_ride_pendlerportal", //'pendlerportal',"car", // "ride_and_carshare_deer", //"ride_and_carshare_regiomobil_elektro", //)"bike_and_ride", //"ride_and_taxi", //"ride_and_carshare_scouter", //"train", //"park_and_ride", //"train", //"park_and_ride", //"bike_and_ride", //"ride_and_carshare_regiomobil_elektro", //"park_and_ride", //"ride_and_carshare_regiomobil_elektro", // , //,// "park_and_ride", //"ride_and_carshare_regiomobil_elektro",//"ride_and_escooter_ffm", //"park_and_ride", //"bike_and_ride",//"ride_and_carshare_regiomobil_elektro", //"park_and_ride",//"bike_ride_and_rollershare_felyx", //"park_and_ride",//"bike_and_ride", //"train",// "park_and_ride", ,// "park_and_ride",// , "train",//"bike_ride_and_carshare_rabe",//"park_and_ride", // "train",// "ride_and_carshare_scouter", //"carshare_deer",// "train",//"ride_and_carshare_cambio",//"train",//"bike_ride_and_carshare_regiomobil_verbrenner",//"bike_ride_and_carshare_regiomobil_verbrenner",//"carsharing_scouter",//"bike_ride_and_carshare_regiomobil_verbrenner", //"carsharing_scouter", //"ride_and_taxi",// "ebike_and_ride",// "eScooter", //"ride_and_carshare_regiomobil_verbrenner", //'pendlerportal_bike',//"bikeshare_and_ride_Callabike",//"train", //'bikeshare_and_ride_nextbike',//'ride_and_carshare_regiomobil_elektro',//,
      transportGroupToFilter: undefined, // "modal_only_cars",
      geojsonRoutes: [],
      transportTypesArray: [...TransportTypesArray],
      routesByTransportType: null,
      checkedTransportsDict: utils.getInitCheckedTransports(),
      checkedProvidersDict: this.getInitCheckedProvidersWrapper(),
      innerWidth: window.innerWidth,
      mapHeightDesktopScreen: "500px",
      mapHeightMobileScreen: "300px",
    };
  },

  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
    },
    isSmallScreenWidth() {
      return this.innerWidth < 600;
    },
    showMoreRoutesClicked() {
      // this.fetchRoutesWrapper(true, true);
      this.fetchSingleTransportGroupRoutesWrapper(true, [
        TransportModalTypes.INTERMODAL,
      ]);
    },
    updateMinutesCongestion(minCongestion) {
      this.searchParams.minutesCongestion = minCongestion;
    },
    updateMinutesParking(minParking) {
      this.searchParams.minutesParking = minParking;
    },
    updateIncludeIntermodal(includeIntermodal) {
      this.searchParams.includeIntermodal = includeIntermodal;
    },
    updateCheckedTransports(key, val) {
      this.checkedTransportsDict[key] = val;
    },
    updateCheckedProviders(key, val) {
      this.checkedProvidersDict[key] = val;
    },
    locInputChanged(geocodeObj, inputKey) {
      utils.locInputChangedExternal.bind(this, geocodeObj, inputKey)();
      if (
        this.searchParams.originLonLat ||
        this.searchParams.destinationLonLat
      ) {
        var element = this.$refs["searchbar"];
        // var top = element.offsetTop;
        element.$el.scrollIntoView({ behavior: "smooth" });
        // window.scrollTo(0, top);
      }
    },
    async applyFrontendConfig() {
      this.config = await frontendConfig();

      // call generilzed applyConfig function to assign configs like primary/secondary color
      const boundFunc = utils.applyConfig.bind(this);
      boundFunc();

      this.initCheckedProviders();

      const { pageTitle } = this.config.frontend;
      this.pollOverviewData = this.config.frontend.poll;
      document.title = pageTitle; //`CleverRoute - Ihre Anreise ${placename}`;
    },
    async applyFullConfig() {
      this.config = await config();
      // after fullConfig is loaded set pageLoading in store to false
      useConfigStore().setPageLoading(false);
    },
    async fetchSingleRouteWrapper(scrollToRoutes = true, modalTypesToRetrieve) {
      if (!fetchRouteParamsValid(this.searchParams, this.config.destinations))
        return;
      // reset variable each time a new search is triggered
      this.showMoreRoutesHintClicked = false;
      useConfigStore().setSearchRunning(true);
      try {
        const p = clone(this.searchParams);
        // p.origin = [9.56777403618144, 50.836949364455656]
        /* to avoid error due to missing departure time, send the departure time same as arrival time
        as we are sending the flag onlyJourneyThere there will be no confusion on the server side and
        the invalid departure time is not processed
        */
        if (!p.departure || p.departure === "") {
          this.onlyJourneyThere = true;
          // delete departure param if it is empty anyway
          delete p.departure;
        } else {
          this.onlyJourneyThere = false;
        }

        // set parameter for journey there time orientation e.g. arrival or departure time
        const journeyTimeOrientation = storeToRefs(
          useConfigStore()
        ).metricToJourneyThereTimeOrientation;

        p.useDepartureTimeForJourneyThere =
          journeyTimeOrientation.value === "arrival" ? false : true;

        if (modalTypesToRetrieve !== undefined) {
          // if intermodal is requested then set the search-param accordingly
          p.includeIntermodal = modalTypesToRetrieve.includes(
            TransportModalTypes.INTERMODAL
          );
        }
        this.setURLParams(p);
        if (modalTypesToRetrieve !== undefined) {
          this.showMoreRoutesHintClicked = modalTypesToRetrieve.includes(
            TransportModalTypes.INTERMODAL
          );
        } else {
          this.showMoreRoutesHintClicked = this.searchParams.includeIntermodal
            ? true
            : false;
          modalTypesToRetrieve = this.searchParams.includeIntermodal
            ? [TransportModalTypes.MULTI, TransportModalTypes.INTERMODAL]
            : [TransportModalTypes.MULTI];
        }

        let filteredTransports = this.filterByModalType(modalTypesToRetrieve); //this.filterByIntermodal();
        let mappedIds = filteredTransports.map((t) => t.id);

        // If modal transport types are requested then remove old routes, as it means automatically that it's a new search
        if (
          modalTypesToRetrieve &&
          modalTypesToRetrieve.includes(TransportModalTypes.MULTI)
        ) {
          this.routes = [];
          this.nLoadedRoutes = 0;
          // reset Array and object for checked transporttypes and transports grouped into a transportType
          this.initCheckedTransports();
          this.initRoutesByTransportType();
        }
        from(mappedIds)
          .pipe(mergeMap((tId) => fetchSingleRoute({ ...p, transportId: tId })))
          .subscribe((res) => {
            this.nLoadedRoutes = this.nLoadedRoutes + 1;
            //        const body =  res.json()
            // if (body.status === 'success') {
            if (Object.keys(res).length > 0) {
              this.routes.push(res);
            }
          });

        // this.routes = await fetchSingleRoute(p);
        if (scrollToRoutes) this.scrollToRoutes();
      } catch (err) {
        console.error(err);
        this.error.msg = err.message;
        this.error.show = true;
      }
    },
    async fetchSingleTransportGroupRoutesWrapper(
      scrollToRoutes = true,
      modalTypesToRetrieve
    ) {
      if (!fetchRouteParamsValid(this.searchParams, this.config.destinations))
        return;

      // reset variable each time a new search is triggered
      this.showMoreRoutesHintClicked = false;
      useConfigStore().setSearchRunning(true);
      // reset survey input on each journey search, otherwise old choices are still active but for different transport variants (according to the row-index)
      if (this.usePoll) {
        usePollStore().resetSuveyInput();
      }
      
      try {
        const p = clone(this.searchParams);
        // p.origin = [9.56777403618144, 50.836949364455656]
        /* to avoid error due to missing departure time, send the departure time same as arrival time
        as we are sending the flag onlyJourneyThere there will be no confusion on the server side and
        the invalid departure time is not processed
        */
        if (!p.departure || p.departure === "") {
          this.onlyJourneyThere = true;
          // delete departure param if it is empty anyway
          delete p.departure;
        } else {
          this.onlyJourneyThere = false;
        }

        // set parameter for journey there time orientation e.g. arrival or departure time
        const journeyTimeOrientation = storeToRefs(
          useConfigStore()
        ).metricToJourneyThereTimeOrientation;

        p.useDepartureTimeForJourneyThere =
          journeyTimeOrientation.value === "arrival" ? false : true;

        if (modalTypesToRetrieve !== undefined) {
          // if intermodal is requested then set the search-param accordingly
          p.includeIntermodal = modalTypesToRetrieve.includes(
            TransportModalTypes.INTERMODAL
          );
        }
        this.setURLParams(p);
        if (modalTypesToRetrieve !== undefined) {
          this.showMoreRoutesHintClicked = modalTypesToRetrieve.includes(
            TransportModalTypes.INTERMODAL
          );
        } else {
          this.showMoreRoutesHintClicked = this.searchParams.includeIntermodal
            ? true
            : false;
          modalTypesToRetrieve = this.searchParams.includeIntermodal
            ? [TransportModalTypes.MULTI, TransportModalTypes.INTERMODAL]
            : [TransportModalTypes.MULTI];
        }

        let filteredTransportGroups =
          this.filterTransportGroupsByModalType(modalTypesToRetrieve); //this.filterByIntermodal();

        let mappedTransportGroupsIds = filteredTransportGroups.map(
          (tg) => tg.id
        );

        // If modal transport types are requested then remove old routes, as it means automatically that it's a new search
        if (
          modalTypesToRetrieve &&
          modalTypesToRetrieve.includes(TransportModalTypes.MULTI)
        ) {
          this.routes = [];
          this.nLoadedRoutes = 0;
          // reset Array and object for checked transporttypes and transports grouped into a transportType
          this.initCheckedTransports();
          this.initRoutesByTransportType();
        }
        let now = Date.now();
        from(mappedTransportGroupsIds)
          .pipe(
            mergeMap((tgId) => {
              let params = { ...p };
              // if only transportGroup or single transport should be searched for, then  specify as param
              if (this.transportGroupToFilter) {
                params = { ...params, transportGroupId: this.transportGroupToFilter };
              } else if (this.transportNameToFilter) {
                params = { ...params, transportId: this.transportNameToFilter };
              } 
              // ...otherwise use the current transportgroup-id in the loop
              else {
                params = { ...params, transportGroupId: tgId };
              }
              return fetchSingleRoute(params, "singleGroupRoutes");
            })
          )
          .subscribe({
            next: (tg) => {
            let routes = tg.routes;

              // console.log(`tg.id ${tg.id} time= ${(Date.now() - now)/1000}`)
            this.nLoadedRoutes = this.nLoadedRoutes + 1;
            //        const body =  res.json()
            // if (body.status === 'success') {
            if (Object.keys(routes).length > 0) {
              routes.forEach((t) => {
                this.routes.push(t);
              });
            }
            },
            error: (err) => console.error("Error when loading transport groups:", err)
          });
       

        // this.routes = await fetchSingleRoute(p);
        if (scrollToRoutes) this.scrollToRoutes();
      } catch (err) {
        console.error(err);
        this.error.msg = err.message;
        this.error.show = true;
      }
    },
    filterTransportGroupsByIntermodal() {
      const transportGroups = this.config.transportgroupsBuilt;

          // filter out transport groups without transports
      let transportGroupsWithTransports = transportGroups.filter(tg => tg.transports.length > 0);

      let filteredTransportGroups = transportGroupsWithTransports.filter((tg) =>
        this.showMoreRoutesHintClicked ||
        this.searchParams.includeIntermodal ||
        this.searchParams.includeIntermodal === undefined
          ? true
          : tg.isIntermodal === undefined || tg.isIntermodal === false
      );
      if (this.transportGroupToFilter) {
        filteredTransportGroups = filteredTransportGroups.filter(
          (tg) => tg.id === this.transportGroupToFilter
        );
      } else if (this.transportNameToFilter) {
        let filteredTransportGroup = filteredTransportGroups.find(
          (tg) =>
            tg.transports.filter((t) => t.id === this.transportNameToFilter)
              .length > 0
        );
        if (filteredTransportGroup) {
          const transport = filteredTransportGroup.transports.find(
            (t) => t.id === this.transportNameToFilter
          );
          if (transport) {
            filteredTransportGroup = {
              ...filteredTransportGroup,
              transports: [transport],
            };
            filteredTransportGroups = [filteredTransportGroup];
          }
        }
      }

      return filteredTransportGroups;
    },
    filterByIntermodal() {
      const transports = this.config.transports;
      let filteredTransports = transports.filter((t) =>
        this.showMoreRoutesHintClicked ||
        this.searchParams.includeIntermodal ||
        this.searchParams.includeIntermodal === undefined
          ? true
          : t.isIntermodal === undefined || t.isIntermodal === false
      );
      filteredTransports = this.transportNameToFilter
        ? filteredTransports.filter((t) => t.id === this.transportNameToFilter)
        : filteredTransports;
      return filteredTransports;
    },
    /* filtering transports by requested modal-type like modal or intermodal */
    filterByModalType(modalTypes) {
      const transports = this.config.transports;
      // if no modalTypes are passed, then return all transports back
      if (!modalTypes) {
        return transports;
      }
      /* if transport-type is intermodal and intermodal is requested within modalTypes array
       or if the transport-type is not intermodal or undefined or false and modal-type is requested within modalTypes array */
      let filteredTransports = transports.filter(
        (t) =>
          (t.isIntermodal &&
            modalTypes.includes(TransportModalTypes.INTERMODAL)) ||
          (!t.isIntermodal && modalTypes.includes(TransportModalTypes.MULTI))
      );

      filteredTransports = this.transportNameToFilter
        ? filteredTransports.filter((t) => t.id === this.transportNameToFilter)
        : filteredTransports;

      //.filter(t=> t.id==="ride_and_carshare_regiomobil_elektro" );
      // (this.showMoreRoutesHintClicked || this.searchParams.includeIntermodal || this.searchParams.includeIntermodal === undefined) ? true : (t.isIntermodal === undefined || t.isIntermodal === false)); //transports.filter(t => (this.showMoreRoutesHintClicked || this.searchParams.includeIntermodal || this.searchParams.includeIntermodal === undefined) ? true : (t.isIntermodal === undefined || t.isIntermodal === false))
      return filteredTransports;
    },
    /* filtering transport-groups by requested modal-type like modal or intermodal */
    filterTransportGroupsByModalType(modalTypes) {
      const transportGroups = this.config.transportgroupsBuilt;
      // if no modalTypes are passed, then return all transports back
      if (!modalTypes) {
        return transportGroups;
      }
    
      // filter out transport groups without transports
      let transportGroupsWithTransports = transportGroups.filter(tg => tg.transports.length > 0);
      
      /* if transport-type is intermodal and intermodal is requested within modalTypes array
       or if the transport-type is not intermodal or undefined or false and modal-type is requested within modalTypes array */
      let filteredTransportGroups = transportGroupsWithTransports.filter(
        (tg) =>
          (tg.isIntermodal &&
            modalTypes.includes(TransportModalTypes.INTERMODAL)) ||
          (!tg.isIntermodal && modalTypes.includes(TransportModalTypes.MULTI))
      );
      if (this.transportGroupToFilter) {
        filteredTransportGroups = this.transportGroupToFilter
          ? filteredTransportGroups.filter(
              (tg) => tg.id === this.transportGroupToFilter
            )
          : filteredTransportGroups;
      } else if (this.transportNameToFilter) {
        let filteredTransportGroup = filteredTransportGroups.find(
          (tg) =>
            tg.transports.filter((t) => t.id === this.transportNameToFilter)
              .length > 0
        );
        if (filteredTransportGroup) {
          const transport = filteredTransportGroup.transports.find(
            (t) => t.id === this.transportNameToFilter
          );
          if (transport) {
            filteredTransportGroup = {
              ...filteredTransportGroup,
              transports: [transport],
            };
            filteredTransportGroups = [filteredTransportGroup];
          }
        }
      }

      //.filter(t=> t.id==="ride_and_carshare_regiomobil_elektro" );
      // (this.showMoreRoutesHintClicked || this.searchParams.includeIntermodal || this.searchParams.includeIntermodal === undefined) ? true : (t.isIntermodal === undefined || t.isIntermodal === false)); //transports.filter(t => (this.showMoreRoutesHintClicked || this.searchParams.includeIntermodal || this.searchParams.includeIntermodal === undefined) ? true : (t.isIntermodal === undefined || t.isIntermodal === false))
      return filteredTransportGroups;
    },
    async getHeroHeight() {
      await this.$nextTick(); // wait until refs are available
      const searchbar = this.$refs.searchbar;
      const centerElHeight = searchbar.$el.getBoundingClientRect().height;
      const minHeight = this.$vuetify.breakpoint.xs ? 380 : 290;
      const divisor = this.$vuetify.breakpoint.smAndDown ? 1.18 : 2;
      return Math.max(
        minHeight,
        window.innerHeight / divisor - centerElHeight / divisor
      );
    },

    async scrollToRoutes() {
      await this.$nextTick(); // wait until refs are available
      const scrollEl = this.$vuetify.breakpoint.smAndDown
        ? this.$refs.routelist
        : this.$refs.searchbar;
      this.$vuetify.goTo(scrollEl, { duration: 700 });
    },

    parseURLParams() {
      const p = fromQueryString();
      // if departure-time is passed, then onlyJourneyThere is true otherwise false
      this.onlyJourneyThere = p.departure === "" || !p.departure ? true : false;

      // parse Url params related to locality and extended settings like congestion and parking time
      const boundFunc = utils.parseURLParams.bind(this, p);
      boundFunc();
      // set time orientation of journey there
      if (this.searchParams.useDepartureTimeForJourneyThere !== undefined) {
        useConfigStore().setMetricToJourneyThereTimeOrientation(
          this.searchParams.useDepartureTimeForJourneyThere
            ? "departure"
            : "arrival"
        );
      }
      if (isNaN(this.searchParams.departure))
        throw new Error("departure date is invalid");
      if (isNaN(this.searchParams.arrival))
        throw new Error("arrival date is invalid");
    },

    setURLParams(p) {
      const q = `?${toQueryString(p)}`;

      window.history.pushState(p, "", q);
    },
    initRoutesByTransportType() {
      this.routesByTransportType = {};
      // this.transportTypesArray.forEach(tType => this.routesByTransportType[tType] = []);
    },
    initCheckedTransports() {
      this.checkedTransportsDict = { ...utils.getInitCheckedTransports() };
    },

    initCheckedProviders() {
      this.checkedProvidersDict = { ...this.getInitCheckedProvidersWrapper() };
    },
    getInitCheckedProvidersWrapper() {
      if (!this.config?.transports) {
        return {};
      }
      return utils.getInitCheckedProviders(this.config);
    },
  },
  watch: {
    // "searchParams.origin": {
    //   handler: function (n, o) {
    //     console.log("searchparams changed=", n, o);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    /* if number of transports changes, then check if all transport variants are already completed 
    and end the loading status and reset percentage of computed transports, otherwise update percentage of 
    computed transports */
    nLoadedRoutes(newVal, oldVal) {
      if (this.searchRunning.value) {
        let nRoutesWithoutIntermodal = this.filterTransportGroupsByIntermodal();
        this.percentageTransportsComputed =
          (this.nLoadedRoutes / nRoutesWithoutIntermodal.length) * 100;
        // resetting loading-status and percentage value if all transports are computed
        if (this.nLoadedRoutes === nRoutesWithoutIntermodal.length) {
          useConfigStore().setSearchRunning(false);
          this.percentageTransportsComputed = 0;
        }
      }
    },
    routes(newVal, oldVal) {
      this.geojsonRoutes = this.routes.map(
        (r) =>
          new Object({
            type: "GeometryCollection",
            geometries: r.toDestination.legs.map((l) => l.geometry),
            transportType: r.transport.transportType,
          })
      );
      this.routes.forEach((r) => {
        if (!this.routesByTransportType[r.transport.transportType]) {
          this.routesByTransportType[r.transport.transportType] = [r];
        }
        // if transport variant is not included then add it
        else if (
          !this.routesByTransportType[r.transport.transportType].find(
            (localR) => r.transport.id === localR.transport.id
          )
        ) {
          this.routesByTransportType[r.transport.transportType].push(r);
        }
      });
    },
  },
  computed: {
    usePoll() {
      return storeToRefs(usePollStore()).usePoll.value || false;
    },
    /* if shore more routes hint is not clicked yet and number of loaded transport variant is not zero, 
    then show the hint */
    showMoreRoutesHint() {
      if (!this.showMoreRoutesHintClicked && this.nLoadedRoutes > 0) {
        return true;
      } else {
        return false;
      }
    },
    rideshareHost() {
      return this.config?.frontend.clever2gether || "";
    },
    reservationToolHost() {
      return this.config?.frontend.reservationTool || "";
    },
    computeToShowRoutes() {
      const filteredRoutes = this.geojsonRoutes; /*.filter(
        (geo, i) => this.checkedTransportsArray[this.routes[i].transport.transportType]
      );*/
      return filteredRoutes;
    },
    topBarColor() {
      if (this.config?.frontend?.topBarColor) {
        return { "background-color": this.config.frontend.topBarColor };
      } else {
        return {
          "background-color": this.$vuetify.theme.themes.light.secondary,
        };
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  async created() {
    // initiating reactive variable for pageloading
    this.pageLoading = storeToRefs(useConfigStore()).pageLoading;
    useConfigStore().setPageLoading(true);

    // initiating reactive variable for status of search request
    this.searchRunning = storeToRefs(useConfigStore()).searchRunning;
    window.addEventListener("resize", this.onResize);

    try {
      await this.applyFrontendConfig();
      this.parseURLParams();
      await this.applyFullConfig();
      this.fetchSingleTransportGroupRoutesWrapper(false);
    } catch (err) {
      console.error(err);
      this.error.show = true;
      this.error.msg = err.message;
    }

    /* if a predefined destination is passed in URL then retrieve its coordinates by its name so the marker 
      can be set in the map automatically on loading 
      adding also some delay, so its bounds are shown in the map after setGermanyBounds */
    setTimeout(() => utils.parseDestinationByDestinationName.bind(this)(), 300);

    this.initRoutesByTransportType();
    this.initCheckedTransports();
  },
  async mounted() {
    let store = usePollStore();
    this.pollPlz = storeToRefs(store).pollPlz.value;
    this.heroHeight = await this.getHeroHeight();
    this.showMoreRoutesHintClicked = this.searchParams.includeIntermodal
      ? true
      : false;
  },
});
</script>
